import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';
import {getCookie} from './../constants/Cookie';
import Header from './../features/header';
import Footer from './../features/footer';
import Swal from 'sweetalert2'

import {
    LoadingModal
} from '../features/modal';

const PrivacyPolicy = props => {
    return (
        <Page>
            <div id="texte_a_afficher" className="texte_inselectionnable" style={{height: "895px",color: "black" }}>
                <span className="stabilisation"></span>
                <p style={{textAlign: "center"}}><strong>นโยบายความเป็นส่วนตัวสำหรับการใช้งาน<span
                    id="span_id_asktype">เว็บไซต์</span></strong></p>
                <p>นโยบายความเป็นส่วนตัวฉบับนี้ ซึ่งต่อไปนี้จะเรียกว่า "นโยบาย" บังคับใช้ครั้งแรกเมื่อวันที่ <span
                    id="span_id_estabdate">1 กรกฎาคม 2565</span> โดยมีรายละเอียด ดังต่อไปนี้</p>
                <p><strong>ข้อ 1 คำนิยาม</strong></p>
                <p>ภายในนโยบายฉบับนี้</p>
                <p style={{paddingLeft: "30px"}}>(ก) "<span id="span_id_asktype">เว็บไซต์</span>" หมายความว่า <span
                    id="span_id_asktype">เว็บไซต์</span> ชื่อว่า <strong><span id="span_id_awname">kamihikokicafe</span></strong> และมีที่อยู่เว็บไซต์ที่ <span
                    id="span_id_urls">https://kamihikokicafe.com</span>(ข) "ผู้ควบคุมข้อมูล" หมายความว่า
                    ผู้ให้บริการหรือเจ้าของ<span id="span_id_asktype">เว็บไซต์</span> ตามนโยบายฉบับนี้
                    อันได้แก่ <strong><span
                        id="span_id_aname">นายคณพศ ลออสุวรรณ</span></strong> ถือบัตรประจำตัวประชาชนเลขที่ <span
                        id="span_id_aid">1100501383813</span> อยู่ที่ <span id="span_id_aadd">WJ99+HH8, Sanambin, Don Mueang, กรุงเทพมหานคร 10210</span> ติดต่อ <span
                        id="span_id_acontact">0855670555</span>(ค) "ผู้ประมวลผลข้อมูล" หมายความว่า
                    บุคคลภายนอกซึ่งประมวลข้อมูลเพื่อประโยชน์หรือในนามของผู้ควบคุมข้อมูล(ง) "ข้อมูล" หมายความว่า
                    สิ่งที่สื่อความหมายให้รู้เรื่องราวข้อเท็จจริง ข้อมูล หรือสิ่งใดๆ
                    ไม่ว่าการสื่อความหมายนั้นจะทำได้โดยสภาพของสิ่งนั้นเองหรือโดยผ่านวิธีการใดๆ
                    และไม่ว่าจะได้จัดทำไว้ในรูปของเอกสาร แฟ้ม รายงาน หนังสือ แผนผัง แผนที่ ภาพวาด ภาพถ่าย ฟิล์ม
                    การบันทึกภาพหรือเสียง การบันทึกโดยเครื่องคอมพิวเตอร์ โดยวิธีการทางอิเล็กทรอนิกส์
                    หรือวิธีอื่นใดที่ทำให้สิ่งที่บันทึกไว้ปรากฏได้(จ) "ข้อมูลส่วนบุคคล" หมายความว่า
                    ข้อมูลเกี่ยวกับบุคคลธรรมดาใดๆ
                    ซึ่งทำให้สามารถระบุตัวของบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม(ฉ)
                    "ข้อมูลส่วนบุคคลที่มีความอ่อนไหว" หรือ "Sensitive Data" หมายความว่า
                    ข้อมูลส่วนบุคคลของผู้ใช้งานที่เกี่ยวกับเชื้อชาติ เผ่าพันธ์ุ ความคิดเห็นทางการเมือง
                    ความเชื่อในลัทธิ ศาสนา หรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ
                    ความพิการ พันธุกรรม ข้อมูลชีวภาพ ข้อมูลภาพจำลองใบหน้า ม่านตา หรือลายนิ้วมือ
                    ข้อมูลสหภาพแรงงาน
                    หรือข้อมูลอื่นใดซึ่งคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลได้ประกาศให้เป็นข้อมูลส่วนบุคคลที่มีความอ่อนไหว(ช)
                    "ผู้ใช้งาน" หมายความว่า ท่าน ผู้เยี่ยมชม ผู้ใช้ ผู้เป็นสมาชิกของ<span
                        id="span_id_asktype">เว็บไซต์</span> ซึ่งเป็นเจ้าของข้อมูลส่วนบุคคลตามนโยบายฉบับนี้
                </p>
                <p><strong>ข้อ 2 ความยินยอมของผู้ใช้งาน</strong></p>
                <p>ในการเข้าใช้งาน<span
                    id="span_id_asktype">เว็บไซต์</span> ผู้ใช้งานตกลงและให้ความยินยอมเกี่ยวกับการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล
                    ดังต่อไปนี้</p>
                <p style={{ paddingLeft: "30px"}}><strong>(ก)
                    วัตถุประสงค์แห่งการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล </strong><strong></strong></p>
                <p style={{ paddingLeft: "30px"}}>ผู้ใช้งานรับทราบ ตกลง
                    และยินยอมให้ผู้ควบคุมข้อมูลเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล เพื่อวัตถุประสงค์ดังต่อไปนี้เท่านั้น</p>
                <p style={{ paddingLeft: "60px"}}><span id="span_id_purofdatause">เพื่อเข้าใช้งานระบบ</span></p>
                <p style={{ paddingLeft: "30px"}}><strong>(ข) ข้อมูลส่วนบุคคลที่เก็บรวบรวมและใช้</strong><strong></strong>
                </p>
                <p style={{ paddingLeft: "30px"}}>ผู้ใช้งานรับทราบ ตกลง
                    และยินยอมให้ผู้ควบคุมข้อมูลเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล ดังต่อไปนี้เท่านั้น</p>
                <p style={{ paddingLeft: "60px"}}><span id="span_id_datacollected">ไม่มี</span></p>
                <p style={{ paddingLeft: "30px"}}><strong>(ค) ระยะเวลาในการเก็บรวบรวมข้อมูล</strong></p>
                <p style={{ paddingLeft: "30px"}}>ผู้ใช้งานรับทราบ ตกลง
                    และยินยอมให้ผู้ควบคุมข้อมูลเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลเป็นระยะเวลาทั้งสิ้น <strong><span
                        id="span_id_nmonthcollect">12</span> (สิบสอง) เดือน</strong>นับจากวันที่ได้มีความยินยอมให้เก็บรวบรวมและใช้ข้อมูลส่วนบุคคลตามนโยบายฉบับนี้
                </p>


                <p><strong>ข้อ 3 การถอนความยินยอมของผู้ใช้งาน</strong></p>
                <p>ผู้ใช้งานรับทราบว่าผู้ใช้งานมีสิทธิที่จะถอนความยินยอมใดๆ
                    ที่ผู้ใช้งานได้ให้ไว้แก่ผู้ควบคุมข้อมูลตามนโยบายฉบับนี้ได้ ไม่ว่าเวลาใดโดยการดำเนินการ
                    ดังต่อไปนี้</p>
                <p style={{paddingLeft: "30px"}}><span id="span_id_consentrevok">แจ้งเป็นลายลักษณ์อักษรได้ที่ iceknpsuwan@gmail.com</span>
                </p>

                <p><strong>ข้อ 4 บัญชีผู้ใช้</strong></p>
                <p>ในการใช้งาน<span
                    id="span_id_asktype">เว็บไซต์</span> ผู้ควบคุมข้อมูลอาจจัดให้มีบัญชีผู้ใช้ของแต่ละผู้ใช้งานเพื่อการใช้งาน<span
                    id="span_id_asktype">เว็บไซต์</span> โดยที่ผู้ควบคุมข้อมูลมีสิทธิแต่เพียงผู้เดียวในการอนุมัติเปิดบัญชีผู้ใช้
                    กำหนดประเภทบัญชีผู้ใช้ กำหนดสิทธิการเข้าถึงข้อมูลของแต่ละประเภทบัญชีผู้ใช้ สิทธิการใช้งาน<span
                        id="span_id_asktype">เว็บไซต์</span> ค่าใช้จ่ายใดๆ เกี่ยวกับบัญชีผู้ใช้
                    หน้าที่และความรับผิดชอบของผู้ใช้งานซึ่งเป็นเจ้าของบัญชีผู้ใช้นั้นๆ</p>
                <p>ทั้งนี้ ผู้ใช้งานตกลงจะเก็บรักษาชื่อบัญชีผู้ใช้ รหัสผ่าน และข้อมูลใดๆ
                    ของตนไว้เป็นความลับอย่างเคร่งครัด และตกลงจะไม่ยินยอมให้
                    รวมถึงใช้ความพยายามอย่างที่สุดในการป้องกันไม่ให้บุคคลอื่นใช้งานบัญชีผู้ใช้ของผู้ใช้งาน</p>
                <p>ในกรณีที่มีการใช้บัญชีผู้ใช้ของผู้ใช้งานโดยบุคคลอื่น
                    ผู้ใช้งานตกลงและรับรองว่าการใช้งานโดยบุคคลอื่นดังกล่าวได้กระทำในฐานะตัวแทนของผู้ใช้งานและมีผลผูกพันเสมือนหนึ่งผู้ใช้งานเป็นผู้กระทำการเองทั้งสิ้น</p>
                <p><strong>ข้อ 5 สิทธิของผู้ใช้งาน</strong></p>
                <p>ในการเข้าใช้งาน<span id="span_id_asktype">เว็บไซต์</span>ตามนโยบายฉบับนี้และการให้ความยินยอมใดๆ
                    ตามนโยบายฉบับนี้
                    ผู้ใช้งานได้รับทราบถึงสิทธิของตนในฐานะเจ้าของข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลเป็นอย่างดีแล้ว
                    อันรวมถึงแต่ไม่จำกัดเพียงสิทธิของผู้ใช้งาน ดังต่อไปนี้</p>
                <p style={{paddingLeft: "30px"}}>(ก) ผู้ใช้งานอาจถอนความยินยอมที่ให้ไว้ตามนโยบายฉบับนี้เมื่อใดก็ได้
                    โดยการแจ้งเป็นลายลักษณ์อักษรแก่ผู้ควบคุมข้อมูลตามวิธีและช่องทางที่กำหนดในนโยบายฉบับนี้(ข)
                    ผู้ใช้งานมีสิทธิการเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลของตนหรือที่เกี่ยวข้องกับตนที่ผู้ควบคุมข้อมูลได้เก็บรวบรวมเอาไว้ตามนโยบายฉบับนี้(ค)
                    ผู้ใช้งานมีสิทธิได้รับการเปิดเผยจากผู้ควบคุมข้อมูลถึงการได้มาซึ่งข้อมูลส่วนบุคคลของตนหรือที่เกี่ยวข้องกับตนซึ่งตนไม่ได้ให้ความยินยอม
                    หากว่ามีกรณีเช่นว่า(ง)
                    ผู้ใช้งานอาจให้ผู้ควบคุมข้อมูลส่งหรือโอนข้อมูลส่วนบุคคลของตนหรือที่เกี่ยวข้องกับตนไปยังผู้ควบคุมข้อมูลรายอื่น
                    รวมถึงการขอรับข้อมูลที่ได้ส่งหรือโอนดังกล่าวโดยตรงจากผู้ควบคุมข้อมูลที่ส่งหรือโอนข้อมูลนั้นด้วย(จ)
                    ผู้ใช้งานอาจคัดค้านการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของตนหรือที่เกี่ยวข้องกับตนได้ในกรณีดังต่อไปนี้</p>
                <p style={{paddingLeft: "60px"}}>(1) ผู้ควบคุมข้อมูลเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานด้วยความจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของผู้ควบคุมข้อมูลหรือของบุคคลอื่นซึ่งผู้ใช้งานอาจพิสูจน์ได้ว่าตนมีสิทธิดีกว่าผู้ควบคุมข้อมูล(2)
                    ผู้ควบคุมข้อมูลเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานเพื่อเป็นการปฏิบัติตามกฎหมายของผู้ควบคุมข้อมูลซึ่งผู้ใช้งานอาจพิสูจน์ได้ว่าตนมีสิทธิดีกว่าผู้ควบคุมข้อมูล(3)
                    ผู้ควบคุมข้อมูลเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลนั้นไปเพื่อวัตถุประสงค์เกี่ยวกับการตลาดแบบตรง(4)
                    ผู้ควบคุมข้อมูลเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลนั้นไปเพื่อวัตถุประสงค์เกี่ยวกับการศึกษาวิจัยทางวิทยาศาสตร์
                    ประวัติศาสตร์ หรือสถิติ
                    โดยที่การศึกษาวิจัยนั้นไม่มีความจำเป็นในการดำเนินการเพื่อก่อให้เกิดประโยชน์สาธารณะ</p>
                <p style={{paddingLeft: "30px"}}>(ฉ) ผู้ใช้งานอาจให้ผู้ควบคุมข้อมูลดำเนินการลบ ทำลาย
                    หรือทำให้ข้อมูลไม่สามารถระบุตัวบุคคลผู้เป็นเจ้าของข้อมูลได้ ในกรณีดังต่อไปนี้</p>
                <p style={{paddingLeft: "60px"}}>(1)
                    เมื่อข้อมูลส่วนบุคคลหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ในการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลนั้น(2)
                    เมื่อผู้ใช้งานซึ่งเป็นเจ้าของข้อมูลส่วนบุคคลได้ถอนความยินยอมในการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลนั้นและผู้ควบคุมข้อมูลนั้นไม่มีอำนาจอื่นตามกฎหมายที่จะเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลนั้นได้อีกต่อไป(3) เมื่อผู้ใช้งานได้คัดค้านการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลนั้นโดยชอบด้วยกฎหมาย(4) เมื่อข้อมูลส่วนบุคคลได้ถูกเก็บรวบรวม ใช้
                    หรือเปิดเผยโดยไม่ชอบด้วยกฎหมาย กฎ ระเบียบ ข้อบังคับเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
                </p>
                <p style={{paddingLeft: "30px"}}>(ช)
                    ผู้ใช้งานอาจให้ผู้ควบคุมข้อมูลระงับการใช้ข้อมูลส่วนบุคคลนั้นโดยยังคงเก็บรักษาเอาไว้ได้อยู่
                    ในกรณีดังต่อไปนี้</p>
                <p style={{paddingLeft: "60px"}}>(1)
                    ผู้ควบคุมข้อมูลอยู่ในระหว่างการถูกตรวจสอบโดยคณะกรรมการผู้เชี่ยวชาญตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลซึ่งผู้ใช้งานได้ร้องเรียนให้มีการตรวจสอบดังกล่าว(2)
                    ข้อมูลส่วนบุคคลได้ถูกเก็บรวบรวม ใช้ หรือเปิดเผยโดยไม่ชอบด้วยกฎหมาย กฎ ระเบียบ
                    ข้อบังคับเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล(3)
                    ในกรณีที่ผู้ใช้งานมีความจำเป็นต้องการให้ผู้ควบคุมข้อมูลเก็บรักษาข้อมูลส่วนบุคคลของตนเอาไว้เพื่อประโยชน์ในสิทธิเรียกร้องของผู้ใช้งานเอง
                    อันได้แก่ การก่อสิทธิเรียกร้องตามกฎหมายของผู้ใช้งาน
                    การปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย
                    ผู้ใช้งานอาจให้ผู้ควบคุมข้อมูลเพียงระงับการใช้ข้อมูลแทนการดำเนินการลบ ทำลาย
                    หรือทำให้ข้อมูลไม่สามารถระบุตัวบุคคลผู้เป็นเจ้าของข้อมูลได้(4)
                    ผู้ควบคุมข้อมูลอยู่ในระหว่างการพิสูจน์หรือตรวจสอบเพื่อปฏิเสธการคัดค้านการเก็บรวบรวม ใช้
                    หรือเผยแพร่ข้อมูลส่วนบุคคลของผู้ใช้งานตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลซึ่งผู้ใช้งานได้คัดค้านโดยชอบด้วยกฎหมายนั้น
                </p>
                <p style={{ paddingLeft: "30px"}}>(ซ) เมื่อผู้ใช้งานพบเห็นว่าข้อมูลส่วนบุคคลของผู้ใช้งานผิด ล้าหลัง
                    ไม่ชัดเชน ผู้ใช้งานมีสิทธิให้ผู้ควบคุมข้อมูลดำเนินการแก้ไขข้อมูลส่วนบุคคลนั้นให้ถูกต้อง เป็นปัจจุบัน
                    สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิดได้(ฌ)
                    ผู้ใช้งานอาจร้องเรียนต่อคณะกรรมการผู้เชี่ยวชาญตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลในกรณีที่เกี่ยวกับการกระทำการฝ่าฝืนหรือการไม่ปฏิบัติตามกฎหมาย
                    กฎ ระเบียบ ข้อบังคับเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของผู้ควบคุมข้อมูล</p>
                <p><strong>ข้อ 6 การรักษาความมั่นคงปลอดภัย</strong></p>
                <p>ในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลตามนโยบายฉบับนี้
                    ผู้ควบคุมข้อมูลจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยที่เหมาะสมเพื่อป้องกันการสูญหาย การเข้าถึง ใช้
                    เปลี่ยนแปลง แก้ไข หรือการเปิดเผยข้อมูลที่ไม่เป็นไปตามกฎหมาย ด้วยมาตรการ มาตรฐาน
                    เทคโนโลยีและ/หรือด้วยระบบ ดังต่อไปนี้</p>
                <p style={{ paddingLeft: "30px"}}><span
                    id="span_id_dataprotectmeasurement">มีการเข้ารหัสข้อมูลส่วนบุคคล</span></p>

                <p><strong>ข้อ 7 การแก้ไขปรับปรุงข้อมูลส่วนบุคคล</strong></p>
                <p>ผู้ควบคุมข้อมูลจะจัดให้มีระบบและมาตรการตรวจสอบ ดังต่อไปนี้</p>
                <p style={{ paddingLeft: "30px"}}>(ก) ดำเนินการปรับปรุงแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์
                    และไม่ก่อให้เกิดความเข้าใจผิด(ข) ลบ
                    ทำลายข้อมูลส่วนบุคคลที่เกินระยะเวลาเก็บรวบรวมที่ผู้ใช้งานได้ให้ความยินยอมเอาไว้ และ(ค) ลบ
                    ทำลายข้อมูลส่วนบุคคลที่ไม่เกี่ยวข้องกับการใช้ข้อมูลส่วนบุคคลดังกล่าวตามที่ผู้ใช้งานได้ให้ความยินยอมเอาไว้
                </p>
                <p><strong>ข้อ 8 การเก็บรวบรวม
                    ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล</strong></p>
                <p>ผู้ใช้งานรับทราบและตกลงว่าผู้ควบคุมข้อมูลอาจเก็บรวบรวม ใช้
                    และ/หรือเปิดเผยข้อมูลของผู้ใช้งานได้โดยไม่ต้องได้รับความยินยอมจากผู้ใช้งานก่อนล่วงหน้า
                    ทั้งนี้เท่าที่จำเป็นและตราบเท่าที่เป็นไปตามวัตถุประสงค์และในกรณีดังต่อไปนี้เท่านั้น</p>
                <p style={{ paddingLeft: "30px"}}>(ก)
                    เพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวกับการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุเพื่อประโยชน์สาธารณะ
                    หรือเกี่ยวกับการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสมเพื่อคุ้มครองสิทธิและเสรีภาพของข้อมูลส่วนบุคคลของผู้ใช้งาน(ข)
                    เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคลใดๆ(ค)
                    เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้นเป็นคู่สัญญาหรือเพื่อใช้ในการดำเนินการตามคำขอของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลก่อนเข้าทำสัญญาดังกล่าวนั้น(ง)
                    เป็นการจำเป็นเพื่อการปฏิบัติหน้าที่ในการดำเนินการเพื่อประโยชน์สาธารณะของผู้ควบคุมข้อมูลหรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่ได้มอบให้แก่ผู้ควบคุมข้อมูลนั้น(จ)
                    เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของผู้ควบคุมข้อมูลหรือของบุคคลอื่นซึ่งประโยชน์ดังกล่าวมีความสำคัญมากกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของผู้ใช้งานนั้น(ฉ)
                    เป็นการปฏิบัติตามกฎหมายของผู้ควบคุมข้อมูล</p>
                <p>ทั้งนี้ ผู้ควบคุมข้อมูลจะบันทึกการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานตามวรรคก่อนหน้าไว้เป็นสำคัญ</p>
                <p><strong>ข้อ 9 การเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive
                    Data) </strong></p>
                <p>ผู้ใช้งานรับทราบและตกลงว่านอกจากการเก็บรวบรวม
                    ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลซึ่งผู้ใช้งานได้ให้ความยินยอมไว้โดยชัดแจ้งให้เก็บรวบรวม
                    ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลตามนโยบายฉบับนี้แล้ว ผู้ควบคุมข้อมูลอาจเก็บรวบรวม
                    ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Data)
                    ของผู้ใช้งานได้โดยไม่ต้องได้รับความยินยอมจากผู้ใช้งานก่อนล่วงหน้า
                    ทั้งนี้เท่าที่จำเป็นและตราบเท่าที่เป็นไปตามวัตถุประสงค์และในกรณีดังต่อไปนี้เท่านั้น</p>
                <p style={{paddingLeft: "30px"}}>(ก) เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
                    หรือสุขภาพของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลซึ่งไม่สามารถให้ความยินยอมได้ ไม่ว่าด้วยเหตุใดก็ตาม(ข)
                    เป็นข้อมูลที่เปิดเผยต่อสาธารณะด้วยความยินยอมโดยชัดแจ้งของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น(ค)
                    เป็นการจำเป็นเพื่อการก่อตั้ง การปฏิบัติตาม
                    การใช้หรือการยกขึ้นต่อสู้ซึ่งสิทธิเรียกร้องตามกฎหมาย(ง)
                    เป็นการจำเป็นในการปฏิบัติตามกฎหมายเพื่อให้บรรลุวัตถุประสงค์อันเกี่ยวกับ</p>
                <p style={{paddingLeft: "60px"}}>(1) เวชศาสตร์ป้องกันหรืออาชีวเวชศาสตร์
                    การประเมินความสามารถในการทำงานของลูกจ้าง การวินิจฉัยโรคทางการแพทย์
                    การให้บริการด้านสุขภาพหรือด้านสังคม การรักษาทางการแพทย์ การจัดการด้านสุขภาพ
                    หรือระบบและการให้บริการด้านสังคมสงเคราะห์ (2) ประโยชน์สาธารณะด้านการสาธารณสุข เช่น
                    การป้องกันด้านสุขภาพจากโรคติดต่ออันตรายหรือโรคระบาดที่อาจติดต่อหรือแพร่เข้ามาในราชอาณาจักร
                    หรือการควบคุมมาตรฐานหรือคุณภาพของยา เวชภัณฑ์ หรือเครื่องมือแพทย์
                    ซึ่งได้จัดให้มีมาตรการที่เหมาะสมและเจาะจงเพื่อคุ้มครองสิทธิเสรีภาพของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลโดยเฉพาะการรักษาความลับของข้อมูลส่วนบุคคลตามหน้าที่หรือตามจริยธรรมแห่งวิชาชีพ(3)
                    การคุ้มครองแรงงาน การประกันสังคม หลักประกันสุขภาพแห่งชาติ
                    สวัสดิการเกี่ยวกับการรักษาพยาบาลของผู้มีสิทธิตามกฎหมาย
                    การคุ้มครองผู้ประสบภัยจากรถหรือการคุ้มครองทางสังคม
                    ซึ่งการเก็บรวมรวมข้อมูลส่วนบุคคลของผู้ใช้งานนั้นเป็นสิ่งที่จำเป็นในการปฏิบัติตามสิทธิหรือหน้าที่ของผู้ควบคุมข้อมูลหรือผู้ใช้งานเจ้าของข้อมูล
                    โดยได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น(4)
                    การศึกษาวิจัยทางวิทยาศาสตร์ ประวัติศาสตร์ หรือสถิติ หรือประโยชน์สาธารณะอื่น ทั้งนี้
                    ด้วยการเก็บรวบรวม ใช้
                    และ/หรือเปิดเผยเพียงเท่าที่จำเป็นและได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้นตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลได้ประกาศกำหนด(5)
                    ประโยชน์สาธารณะที่สำคัญ
                    โดยได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น
                </p>
                <p>ทั้งนี้ ผู้ควบคุมข้อมูลจะบันทึกการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานตามวรรคก่อนหน้าไว้เป็นสำคัญ</p>
                <p><strong>ข้อ 10 การใช้งาน<span id="span_id_asktype">เว็บไซต์</span>ของบุคคลซึ่งอยู่ในความปกครอง อนุบาล
                    หรือพิทักษ์ของผู้ใช้งาน</strong></p>
                <p>ผู้ใช้งานรับรองว่าจะตนไม่ใช่และจะไม่ยินยอมให้บุคคลซึ่งมีลักษณะ ดังต่อไปนี้ เยี่ยมชม ใช้งาน
                    หรือเป็นสมาชิกของ<span id="span_id_asktype">เว็บไซต์</span></p>
                <p style={{paddingLeft: "30px"}}>(ก) คนไร้ความสามารถซึ่งอยู่ในความอนุบาลของผู้ใช้งาน(ข)
                    คนเสมือนไร้ความสามารถซึ่งอยู่ในความพิทักษ์ของผู้ใช้งาน</p>
                <p>ในกรณีที่ผู้ใช้งานยินยอมให้บุคคลลักษณะดังกล่าวข้างต้นเยี่ยมชม ใช้งาน หรือเป็นสมาชิกของ<span
                    id="span_id_asktype">เว็บไซต์</span> ผู้ใช้งานตกลงให้ถือว่าผู้ใช้งานได้ใช้อำนาจปกครอง อนุบาล
                    หรือพิทักษ์ของบุคคลดังกล่าว แล้วแต่กรณี
                    ในการตกลงและให้ความยินยอมตามนโยบายฉบับนี้ทั้งสิ้นเพื่อและในนามของบุคคลดังกล่าวด้วย</p>
                <p><strong>ข้อ 11 การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ</strong></p>
                <p>ผู้ควบคุมข้อมูลอาจส่งหรือโอนข้อมูลส่วนบุคคลของผู้ใช้งานไปยังต่างประเทศได้ในกรณีดังต่อไปนี้</p>
                <p style={{paddingLeft: "30px"}}>(ก)
                    ประเทศปลายทางหรือองค์การระหว่างประเทศที่รับข้อมูลส่วนบุคคลนั้นมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอตามที่กฎหมาย
                    กฎ ระเบียบ ข้อบังคับเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล(ข)
                    ได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล
                    โดยที่ผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลได้รับแจ้งและรับทราบถึงมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่ไม่เพียงพอของประเทศปลายทางหรือองค์การระหว่างประเทศที่รับข้อมูลนั้นแล้ว(ค)
                    เป็นการปฏิบัติตามกฎหมาย(ง)
                    เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญานั้นหรือเพื่อใช้ในการดำเนินการตามคำขอของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลก่อนการเข้าทำสัญญานั้น(จ)
                    เป็นการกระทำการตามสัญญาระหว่างผู้ควบคุมข้อมูลกับบุคคลอื่นโดยเป็นไปเพื่อประโยชน์ของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น(ฉ)
                    เพื่อป้องกับหรือระงับอันตรายต่อชีวิต ร่างกาย
                    หรือสุขภาพของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้นหรือบุคคลใดๆ
                    เมื่อเจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ความยินยอมในขณะนั้นได้(ช)
                    เป็นการจำเป็นเพื่อการดำเนินภารกิจเพื่อประโยชน์สาธารณะที่สำคัญ</p>
                <p><strong>ข้อ 12 การแจ้งเตือนเหตุการละเมิดข้อมูลส่วนบุคคล</strong></p>
                <p>ในกรณีที่ผู้ควบคุมข้อมูลทราบถึงการละเมิดข้อมูลส่วนบุคคลไม่ว่าจะมีการละเมิดโดยบุคคลใด
                    ผู้ควบคุมข้อมูลจะดำเนินการดังต่อไปนี้</p>
                <p style={{paddingLeft: "30px"}}>(ก) ในกรณีมีความเสี่ยงที่จะมีผลกระทบต่อสิทธิหรือเสรีภาพของบุคคลใดๆ
                    ผู้ควบคุมข้อมูลจะแจ้งเหตุการละเมิดข้อมูลส่วนบุคคลดังกล่าวต่อสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
                    โดยไม่ชักช้าเท่าที่จะสามารถกระทำได้ภายใน 72 (เจ็ดสิบสอง) ชั่วโมงนับแต่ทราบเหตุ(ข)
                    ในกรณีมีความเสี่ยงที่จะมีผลกระทบอย่างสูงต่อสิทธิหรือเสรีภาพของบุคคลใดๆ
                    ผู้ควบคุมข้อมูลจะแจ้งเหตุการละเมิดข้อมูลส่วนบุคคลดังกล่าวและแนวทางการเยียวยาต่อสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลและต่อผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น
                    โดยไม่ชักช้าเท่าที่จะสามารถกระทำได้ภายใน 72 (เจ็ดสิบสอง) ชั่วโมงนับแต่ทราบเหตุ</p>
                <p><strong>ข้อ 13 การร้องเรียนและการแจ้งปัญหาเกี่ยวกับข้อมูลส่วนบุคคล</strong></p>
                <p>ผู้ใช้งานอาจร้องเรียนและรายงานปัญหาเกี่ยวกับข้อมูลส่วนบุคคล อันรวมถึงแต่ไม่จำกัดเพียง
                    การขอให้ผู้ควบคุมข้อมูลแก้ไขปรับปรุงข้อมูลให้เป็นปัจจุบันและ/หรือให้ถูกต้อง
                    การคัดค้านการเก็บรวบรวมข้อมูล หรือระงับการใช้ข้อมูล ได้ที่ช่องทางดังต่อไปนี้</p>
                <p style={{paddingLeft: "30px"}}><span id="span_id_datainqurecontact">0855670555</span></p>
                <p><strong>ข้อ 14 การบันทึกรายการสำคัญ </strong></p>
                <p>เว้นแต่กฎหมายคุ้มครองข้อมูลส่วนบุคคลจะกำหนดให้สิทธิผู้ควบคุมข้อมูลไว้เป็นเป็นอย่างอื่น
                    ผู้ควบคุมข้อมูลจะบันทึกรายการสำคัญเกี่ยวกับการจัดเก็บ การใช้
                    หรือการเปิดเผยข้อมูลเป็นหนังสือหรือระบบอิเล็กทรอนิกส์เพื่อการตรวจสอบจากผู้ใช้งานเจ้าของข้อมูลหรือจากหน่วยงานของรัฐ
                    อันรวมถึงแต่ไม่จำกัดเพียงรายการ ดังต่อไปนี้</p>
                <p style={{paddingLeft: "30px"}}>(ก) ข้อมูลส่วนบุคคลที่มีการเก็บรวบรวม(ข)
                    วัตถุประสงค์ของการเก็บรวบรวมข้อมูลส่วนบุคคลแต่ละประเภท(ค) ข้อมูลเกี่ยวกับผู้ควบคุมข้อมูล (ง)
                    ระยะเวลาการเก็บรักษาข้อมูลส่วนบุคคล(จ) สิทธิและวิธีการเข้าถึงข้อมูลส่วนบุคคล
                    รวมทั้งเงื่อนไขเกี่ยวกับบุคคลที่มีสิทธิเข้าถึงข้อมูลส่วนบุคคลและเงื่อนไขในการเข้าถึงข้อมูลส่วนบุคคลนั้น (ฉ)
                    การเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลที่ได้รับยกเว้นไม่ต้องขอความยินยอมจากผู้ใช้งานเจ้าของข้อมูล(ช)
                    การปฏิเสธคำขอและการคัดค้านต่างๆ (ซ)
                    รายละเอียดเกี่ยวกับมาตรการรักษาความมั่นคงปลอดภัยในข้อมูลส่วนบุคคล</p>
                <p><strong>ข้อ 15 การแก้ไขเปลี่ยนแปลงนโยบาย</strong></p>
                <p>ผู้ควบคุมข้อมูลอาจแก้ไขและเปลี่ยนแปลงข้อความในนโยบายฉบับนี้ได้ ไม่ว่าเวลาใดก็ตาม
                    และไม่ว่าทั้งหมดหรือบางส่วน
                    โดยผู้ควบคุมข้อมูลจะแจ้งให้ผู้ใช้งานทราบเมื่อมีการเปลี่ยนแปลงในแต่ละคราวเพื่อให้ผู้ใช้งานได้พิจารณาและดำเนินการยอมรับด้วยวิธีการทางอิเล็กทรอนิกส์หรือวิธีการอื่นใด
                    และหากว่าผู้ใช้งานได้ดำเนินการเพื่อยอมรับนั้นแล้วให้ถือว่านโยบายที่แก้ไขเพิ่มเติมดังกล่าวเป็นส่วนหนึ่งของนโยบายฉบับนี้ด้วย </p>
                <p>อนึ่ง
                    ผู้ใช้งานอาจเข้าถึงนโยบายความเป็นส่วนตัวที่มีการแก้ไขและเปลี่ยนแปลงล่าสุดได้จากแหล่งที่ผู้ควบคุมข้อมูลจัดแสดงไว้จากช่องทาง
                    ดังต่อไปนี้</p>
                <p style={{paddingLeft: "30px"}}><span style={{whiteSpace:"pre-wrap"}}><span id="span_id_uptodatesoureofpp"
                                                                                         className="encours">https://kamihikokicafe.com/privacypolicy</span></span>
                </p>
                <p><strong>ข้อ 16 ความสัมพันธ์ของคู่สัญญา</strong></p>
                <p>โดยที่คู่สัญญาทั้งสองฝ่ายเข้าใจและทราบดีว่า
                    การเข้าทำนโยบายฉบับนี้ไม่ทำให้คู่สัญญาและพนักงานของคู่สัญญาแต่ละฝ่ายมีความสัมพันธ์ในฐานะเป็นลูกจ้างตามกฎหมายแรงงานหรือเป็นหุ้นส่วนกันตามกฎหมายหุ้นส่วนและบริษัทแต่อย่างใด</p>
                <p><strong>ข้อ 17 การโอนสิทธิ</strong></p>
                <p>เว้นแต่จะได้กำหนดไว้เป็นอย่างอื่นอย่างชัดแจ้งในนโยบายฉบับนี้ คู่สัญญาตกลงจะไม่โอนสิทธิ หน้าที่
                    และ/หรือความรับผิดตามนโยบายฉบับนี้ให้แก่บุคคลใดโดยมิได้รับความยินยอมเป็นลายลักษณ์อักษรจากคู่สัญญาอีกฝ่ายหนึ่งเป็นการล่วงหน้าก่อน</p>
                <p><strong>ข้อ 18 การระงับข้อพิพาท</strong></p>
                <p>หากมีข้อโต้เถียง ข้อขัดแย้งใดๆ เกิดขึ้นอันเนื่องมาจากนโยบายฉบับนี้ หากคู่สัญญาไม่สามารถตกลงกันได้
                    คู่สัญญาตกลงจะนำข้อพิพาทดังกล่าวขึ้นฟ้องต่อศาลในประเทศไทย</p></div>

        </Page>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivacyPolicy);

const Page = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    // padding: 80px 0 0px;
    background: white;
    @media screen and (max-width: 450px){
        // padding: 58px 0 0px;
    }
    .login{
        &__inner{
            width: 100%;
            display: flex;
            //align-items: center;
            background: #fff;
            @media screen and (orientation: portrait) , (max-width: 850px){
                flex-direction: column-reverse;
            }
        }
        &__detail{
            width: 50%;
            padding: 2% 6%;
            display: flex;
            flex-direction: column;
            justify-content: center;
             @media screen and (orientation: portrait) , (max-width: 850px){
                width: 100%;
                padding: 0% 8% 8% 8%;
                text-align: center;
            }
        }
        &__banner{
            width: 50%;
            display: block;
            @media screen and (orientation: portrait) , (max-width: 850px){
                width: 100%;
            }
        }
        &__head{
            font-family: 'Poppins-Medium', 'tahoma';
            font-size: 1.6em;
            color: #946e3f;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__sub-head{
            font-size: 1.5em;
            color: #4E5456;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__text{
            font-family: 'Kanit-Light', 'tahoma';
            font-size: .8em;
            color: #4E5456;
        }
        &__row{
            width: 100%;
            margin: 3% auto;
            text-align: left;
            line-height: 0;
            @media screen and (orientation: portrait) , (max-width: 850px){
                max-width: 500px;
                text-align: center;
            }
        }
        &__row-text{
            font-family: 'Poppins-Light', 'tahoma';
            display: inline-block;
            vertical-align: middle;
            font-size: .8em;
            color: #4E5456;
            width: 6em;
        }
        &__row-input{
            width: 70%;
            display: inline-block;
            vertical-align: middle;
            width: 15em;
            font-size: .8em;
            color: #4E5456;
            padding: .5em .5em;
            border: 1px solid #707070;
            border-radius: 5px;
        }
        &__btn{
            background: #946e3f;
            color: #fff;
            font-size: .8em;
            text-align: center;
            width: 8em;
            border-radius: 1.2em;
            padding: .5em 0;
            cursor: pointer;
            transition: .3s opacity;
            &:hover{
                opacity: .9;
            }
             @media screen and (orientation: portrait) , (max-width: 850px){
                margin: 0 auto;
            }

        }
        &__btnregister{
            background: #946e3f;
            color: #fff;
            font-size: .8em;
            text-align: center;
            width: 8em;
            border-radius: 1.2em;
            padding: .5em 0;
            cursor: pointer;
            transition: .3s opacity;
            margin-top : 1px;
            &:hover{
                opacity: .9;
            }
             @media screen and (orientation: portrait) , (max-width: 850px){
                margin: 1px auto;
            }

        }
    }
`;