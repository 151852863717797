import {Component} from 'react';
import jwt_decode from 'jwt-decode';
import {connect} from 'react-redux';
import {getCookie, delCookie, setCookie} from './../constants/Cookie';
import {QueryString} from './../constants/QueryString';
import {
	setLoginUrl,
	setLogoutUrl,
	setValues
} from './../store/redux';

class OauthMiddleware extends Component {

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.doLogout !== this.props.doLogout) {
			if (this.props.doLogout === true) {
				this.logout();
			}
		}
	}

	componentDidMount() {
		let jwtToken = getCookie(process.env.REACT_APP_JWT_COOKIE_NAME);
		if(jwtToken !== ''){
			//this.getInfo();
		}
	}

	logout() {
		console.log(1);
		delCookie(process.env.REACT_APP_JWT_COOKIE_NAME, process.env.REACT_APP_COOKIE_DOMAIN);
		window.location.href = '/'+this.props.match.params.slug+'/login';
	}
	getInfo(){
		this.props.setValues({
			modal_open: 'loading'
		})
		// fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_GRAPH, {
		// 	mode: 'cors',
        //     method: 'post',
        //     headers: {
        //         'Content-Type': 'application/json',
		// 		'credentials': 'include',
        //     },
        //     body: JSON.stringify({
        //         ql: 'userInfo'
        //     })
		// }).then(response =>{
        //     if (response.status == 200) {
        //         response.json().then(response => {
        //             this.props.setValues({
		// 				userInfo: response.data.userInfo,
		// 				modal_open: ''
		// 			})
        //         });
        //     }
        //     else{
        //         throw new Error(response.status);
        //     }
        // }).catch(error => {
		// 	console.log('API Error : ', error)
		// 	this.logout();
        // })
	}
	render() {
		return (false);
	}
}

const mapStateToProps = (state) => ({
	loginUrl: state.Main.loginUrl,
	logoutUrl: state.Main.logoutUrl,
	userData: state.Main.userData,
	doLogout: state.Main.doLogout
})

const mapDispatchToProps = { setLoginUrl, setLogoutUrl, setValues };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OauthMiddleware);
