import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// Page Components
import Empty from './pages/Empty';
import Contact from './pages/Contact';
import Content from './pages/Content';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Dashboard from './pages/Dashboard';
import configStore from './store/ConfigStore';
// Route Middleware
import OauthMiddleware from './middleware/OauthMiddleware';
import './styles/index.scss';
const { store, persistor } = configStore();

const Routes = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <>
                    <Route path={process.env.REACT_APP_EVENT_PATH} exact component={Empty}/>
                    {/*<Route path={process.env.REACT_APP_EVENT_PATH + "login"} exact component={NewPOS}/>*/}
                    <Route path={process.env.REACT_APP_EVENT_PATH+"login"} exact component={Login}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH+"register"} exact component={Register}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH+"privacypolicy"} exact component={PrivacyPolicy}/>
                    <Route path={process.env.REACT_APP_EVENT_PATH+"dashboard"} exact component={Dashboard}/>
                </>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

export default Routes;
