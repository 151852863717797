import React, {Component, Fragment, useEffect, useState} from 'react'
import {connect} from "react-redux";
import {setValues} from '../store/redux';
import {setValues as setModalValues} from '../features/modal/redux';
import {imgList} from './../constants/ImagesList';
import styled from "styled-components";
import {getCookie, setCookie,delCookie} from "../constants/Cookie";
import Header from "../features/header";
import Moment from "moment";
import Footer from "../features/footer";
import Swal from "sweetalert2";
import QRCode from "react-qr-code";


const Dashboard = props => {
    const [loadData, setLoadData] = useState([]);
    const [loadReport, setLoadReport] = useState([]);
    const [qrText, setQrText] = useState("");
    useEffect(() => {
        if(getCookie("login") === ''){
            window.location.href = '/login';
        } else {
            getProfile(getCookie("login"))
        }
    }, []);


    const getProfile = key => {
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_PROFILE, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify({
                key: key,
            })
        }).then(response =>{
            if (response.status === 200) {
                props.setModalValues({
                    modal_open: ''
                })
                response.json().then(response => {
                    if(!response.status){
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: response.message,
                            confirmButtonColor: '#0F3492',
                        })
                        window.location.href = '/login';
                        delCookie("login")
                    } else {
                        setLoadData(response.member)
                        setQrText(response.member.id)
                        setLoadReport(response.reports)
                    }
                });
            }
            else{
                props.setModalValues({
                    modal_open: ''
                })
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    confirmButtonColor: '#0F3492',
                })
            }
        }).catch(error => {
            console.log('API Error : ', error)
        })
    }

    return (
        <>
            <Header
                name="KAMI HIKōKI CAFE"
                title="KAMI HIKōKI CAFE"
            />
            <Page className="home">
                <div className="col-md-12 home__layout" style={{padding: "0",position: "absolute"}}>
                    <img src="https://kamihikokicafe.sgp1.digitaloceanspaces.com/bg.jpeg" alt="" style={{width: "100%"}}/>
                </div>
                <div className="col-md-12" style={{marginTop: "20%",color: "#897c7c"}}>
                    <div className="card">
                        <div className="card-body" style={{textAlign: "center"}}>
                            <div className="col-md-2">
                                <img src="https://kamihikokicafe.sgp1.digitaloceanspaces.com/DRAFT.png" alt="" style={{borderRadius: "50%",border: "1px solid #e1cdb5",width: "50%",margin: "0 auto"}}/>
                            </div>
                            <div className="col-md-10" style={{fontSize: "x-large", marginTop: "5%"}}>
                                {loadData.first_name} {loadData.last_name}
                            </div>
                            <div className="col-md-10" style={{fontSize: "larger"}}>
                                {loadData.member_id}
                            </div>
                            <div className="col-md-10 mt-3" style={{fontSize: "larger"}}>
                                {qrText !== "" && <QRCode value={""+qrText} size="150"/>}
                            </div>
                        </div>
                        <div className="card-body" style={{textAlign: "center"}}>
                            <div className="col-md-12">
                                {/*<p><img src="https://kamihikokicafe.sgp1.digitaloceanspaces.com/cash.png" alt="" style={{width: "50%",margin: "0 auto"}}/></p>*/}
                                {/*<p style={{fontSize: "larger"}}>{loadData.cashFormat}</p>*/}
                            </div>
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        แต้มคงเหลือ
                                        <div style={{fontSize: "2rem",borderRadius: "20px",borderColor: "#946e3f"}}>{loadData.pointFormat}</div>
                                    </div>
                                </div>
                                {/*<p><img src="https://kamihikokicafe.sgp1.digitaloceanspaces.com/star.png" alt="" style={{width: "50%",margin: "0 auto"}}/></p>*/}
                                {/*<p style={{fontSize: "larger"}}>{loadData.pointFormat}</p>*/}
                            </div>
                        </div>
                        <hr style={{border: "1px solid #eeeeee"}}/>
                        <div className="card-body" style={{textAlign: "center"}}>
                            <div className="col-md-12">
                                <table className="table table-responsive">
                                    <thead>
                                    <tr style={{ backgroundColor: "#946e3f", color: "white"}}>
                                        <th scope="col">CREATE DATE</th>
                                        <th scope="col">NAME</th>
                                        <th scope="col">PRICE</th>
                                        <th scope="col">TOTAL</th>
                                        <th scope="col">DISCOUNT</th>
                                        <th scope="col">SUM</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        loadReport.length > 0 ?
                                            <>
                                                {
                                                    loadReport.map((value,key) => {
                                                        console.log(value)
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{value.createdDate}</td>
                                                                    <td>{value.name}</td>
                                                                    <td>{value.price}</td>
                                                                    <td>X{value.totalNumberMenu}</td>
                                                                    <td>{(value.statusFree === true ? <span className="text-danger" style={{whiteSpace: "nowrap"}}>[ use {10*value.totalNumberMenu} point for discount -{(20*value.totalNumberMenu)} ]</span> : "")}</td>
                                                                    <td>{(value.price * value.totalNumberMenu) - ((value.statusFree === true ? 20 : 0) * value.totalNumberMenu)}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                No data
                                            </>

                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
            <Footer
                title="KAMI HIKōKI CAFE"
            />
        </>
    )
}


const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = {setValues, setModalValues};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);


const Video = styled.div`
  @media screen and (orientation: portrait) , (max-width: 450px) {
    display: none;
  }
`

const PagePrint = styled.div`
  position: relative;
  min-height: 100vh;
  padding: 2% 2% 0px;
  display: none;
  font-family: 'DB-Adman-X', 'tahoma';
 
`;

const Page = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  // padding: 2% 2% 0px;
  // background-image: url(${imgList.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-family: 'DB-Adman-X', 'tahoma';

  .setFont {
    font-family: 'DB-Adman-X', 'tahoma';
  }

  .activeBtn {
    background-color: greenyellow;
  }

  hr {
    border: 1px solid #9CDCF9;
  }

  @media screen and (max-width: 450px) {
    padding: 58px 0 0px;
  }

  .btnMainMenu {
    width: 100%;
    height: 100%;
    color: #757575;
    font-size: 1.2rem;
      // background-image: url(${imgList.icon_coffee});
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center;
  }

  .layoutBTN {
    padding: 0 2%;
  }


  .home {
    &__social {
      display: flex;
      justify-content: space-between;
    }

    &__imagetitle {
      margin: 0 auto;
    }

    &__inner {
      width: 100%;
      background: #fff;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        flex-direction: column-reverse;
      }
    }

    &__detail {
      width: 100%;
      padding: 5% 6%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0px 3px 6px #00000029;
      background: #F8F9FA;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        padding: 8%;
        text-align: center;
      }
    }

    &__detail-2 {
      width: 100%;
      padding: 4% 5%;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        padding: 8%;
        text-align: center;
      }
    }

    &__head {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 1.6rem;
      color: #fff;
      line-height: 5;
      margin-bottom: 2%;
      position: absolute;
      top: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
      @media screen and (orientation: portrait) , (max-width: 765px) {
        margin-bottom: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__sub-head {
      font-size: 1.5em;
      color: #4E5456;
      line-height: 1;
      margin-bottom: 2%;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        margin-bottom: 5%;
      }
    }

    &__titletext {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 10rem;
      color: #fff;
      line-height: 1;
      margin: 10% 0;
      letter-spacing: 2rem;
      @media screen and (orientation: portrait) {
        font-size: 10rem;
        letter-spacing: 2rem;
        margin-left: 8%;
        text-align: -webkit-center;
      }

      @media screen and (max-width: 765px) {
        font-size: 2.7rem;
        letter-spacing: 1rem;
        margin: 0;
      }

    }

    &__text {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 4rem;
      color: #fff;
      line-height: 1;
      margin: 10% 0;

      @media screen and (orientation: portrait) {
        font-size: 3rem;
        white-space: nowrap;
      }
      @media screen and (max-width: 765px) {
        font-size: 1.2rem;
        white-space: nowrap;
      }

    }

    &__detail-2-inner {
      width: 100%;
      display: flex;
      align-items: inherit;
      justify-content: center;
      @media screen and (max-width: 1280px) {
        flex-wrap: wrap;
        max-width: 800px;
        margin: 0 auto;
      }
    }

    &__btn {
      background: #ED592F;
      color: #fff;
      font-size: 1em;
      text-align: center;
      width: 8em;
      border-radius: 1.2em;
      padding: .5em 0;
      margin: 3% auto 0;
      display: block;
      cursor: pointer;
      transition: .3s opacity;

      &:hover {
        opacity: .9;
      }
    }
  }
`;