const SET_LOGIN_URL = 'SET_LOGIN_URL';
const SET_LOGOUT_URL = 'SET_LOGOUT_URL';

const initialState = {
    error: false,
    loading: false,
    hideLoader: false,
    loginUrl: "",
    logoutUrl: "",
    doLogout: false,
    userData: null,

    userInfo: {
      isLogin: false,
      name: null
    },
    surveyInfo: {
      name: {
          th: null,
          en: null
      },
      description: null,
      dimensions_count: 5,
      dimensions: [],
      isPassed: false,
      canSurvey: false
    },
    blogs: [],
    content: [],
    dataCoffee: [],
    answers: [
      [1],
      [2],
      [3],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      []
    ]
};

export const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_URL      : return { ...state, loginUrl: action.value };
    case SET_LOGOUT_URL     : return { ...state, logoutUrl: action.value };
    case "SET_VALUES"       : return { ...state, ...action.value };

    default:
      return state;
  }
};


export const setLoginUrl    = value => ({ type: SET_LOGIN_URL, value});
export const setLogoutUrl   = value => ({ type: SET_LOGOUT_URL, value});
export const setValues      = value => ({ type: 'SET_VALUES', value });
// export const actCloseModal  = value => ({type:"CLOSE",value});


