import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import {setValues} from '../store/redux';
import {setValues as setModalValues} from '../features/modal/redux';
import {imgList} from './../constants/ImagesList';
import styled from "styled-components";
import {getCookie} from "../constants/Cookie";


class Empty extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        if(getCookie(process.env.REACT_APP_JWT_COOKIE_NAME) === ''){
            window.location.href = '/login'
        }
    }


    render() {
        return (
            <>
            </>
        )
    }
}


const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = {setValues, setModalValues};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Empty);


const Video = styled.div`
  @media screen and (orientation: portrait) , (max-width: 450px) {
    display: none;
  }
`

const PagePrint = styled.div`
  position: relative;
  min-height: 100vh;
  padding: 2% 2% 0px;
  display: none;
  font-family: 'DB-Adman-X', 'tahoma';
 
`;

const Page = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2% 2% 0px;
  background-image: url(${imgList.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-family: 'DB-Adman-X', 'tahoma';

  .setFont {
    font-family: 'DB-Adman-X', 'tahoma';
  }

  .activeBtn {
    background-color: greenyellow;
  }

  hr {
    border: 1px solid #9CDCF9;
  }

  @media screen and (max-width: 450px) {
    padding: 58px 0 0px;
  }

  .btnMainMenu {
    width: 100%;
    height: 100%;
    color: #757575;
    font-size: 1.2rem;
      // background-image: url(${imgList.icon_coffee});
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center;
  }

  .layoutBTN {
    padding: 0 2%;
  }


  .home {
    &__social {
      display: flex;
      justify-content: space-between;
    }

    &__imagetitle {
      margin: 0 auto;
    }

    &__inner {
      width: 100%;
      background: #fff;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        flex-direction: column-reverse;
      }
    }

    &__detail {
      width: 100%;
      padding: 5% 6%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0px 3px 6px #00000029;
      background: #F8F9FA;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        padding: 8%;
        text-align: center;
      }
    }

    &__detail-2 {
      width: 100%;
      padding: 4% 5%;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        padding: 8%;
        text-align: center;
      }
    }

    &__head {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 1.6rem;
      color: #fff;
      line-height: 5;
      margin-bottom: 2%;
      position: absolute;
      top: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
      @media screen and (orientation: portrait) , (max-width: 765px) {
        margin-bottom: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__sub-head {
      font-size: 1.5em;
      color: #4E5456;
      line-height: 1;
      margin-bottom: 2%;
      @media screen and (orientation: portrait) , (max-width: 765px) {
        margin-bottom: 5%;
      }
    }

    &__titletext {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 10rem;
      color: #fff;
      line-height: 1;
      margin: 10% 0;
      letter-spacing: 2rem;
      @media screen and (orientation: portrait) {
        font-size: 10rem;
        letter-spacing: 2rem;
        margin-left: 8%;
        text-align: -webkit-center;
      }

      @media screen and (max-width: 765px) {
        font-size: 2.7rem;
        letter-spacing: 1rem;
        margin: 0;
      }

    }

    &__text {
      font-family: 'DB-Adman-X', 'tahoma';
      font-size: 4rem;
      color: #fff;
      line-height: 1;
      margin: 10% 0;

      @media screen and (orientation: portrait) {
        font-size: 3rem;
        white-space: nowrap;
      }
      @media screen and (max-width: 765px) {
        font-size: 1.2rem;
        white-space: nowrap;
      }

    }

    &__detail-2-inner {
      width: 100%;
      display: flex;
      align-items: inherit;
      justify-content: center;
      @media screen and (max-width: 1280px) {
        flex-wrap: wrap;
        max-width: 800px;
        margin: 0 auto;
      }
    }

    &__btn {
      background: #ED592F;
      color: #fff;
      font-size: 1em;
      text-align: center;
      width: 8em;
      border-radius: 1.2em;
      padding: .5em 0;
      margin: 3% auto 0;
      display: block;
      cursor: pointer;
      transition: .3s opacity;

      &:hover {
        opacity: .9;
      }
    }
  }
`;