import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';
import {getCookie,setCookie} from './../constants/Cookie';
import Header from './../features/header';
import Footer from './../features/footer';
import Swal from 'sweetalert2'

import {
    LoadingModal
} from '../features/modal';

const Login = props => {
    const [loadData, setLoadData] = useState(false);
    useEffect(() => {
        if(getCookie("login") !== ''){
            window.location.href = '/dashboard';
        }
        // apiGetSurvey();
    }, []);

    const registerPage = () => {
        window.location.href = '/register';
    }

    const apiLogin = () =>{
        let tel = document.getElementById("login_tel").value;
        let password = document.getElementById("login_password").value
        props.setModalValues({
            modal_open: 'loading'
        })
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_LOGIN, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify({
                tel: tel,
                password: password
            })
        }).then(response =>{
            if (response.status == 200) {
                props.setModalValues({
                    modal_open: ''
                })
                response.json().then(response => {
                    if(!response.status){
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: response.message,
                            confirmButtonColor: '#0F3492',
                        })
                    }
                    else{
                        setCookie("login",response.key,1000000)
                        window.location.href = '/dashboard';
                    }
                });
            }
            else{
                props.setModalValues({
                    modal_open: ''
                })
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    confirmButtonColor: '#0F3492',
                })
            }
        }).catch(error => {
            console.log('API Error : ', error)
        })
    }
    return (
        <Page className="login">
            <form>
                <div className="login__inner">
                    <div className="login__detail">
                        <div className="login__head">เข้าสู่ระบบสมาชิก</div>
                        <div className="login__row">
                            <p>เบอร์โทร</p>
                            <input type="text" className="login__row-input" id="login_tel" placeholder="Ex. 0811234567"/>
                        </div>
                        <div className="login__row">
                            <p>รหัสผ่าน</p>
                            <input type="password" className="login__row-input" id="login_password" placeholder="password"/>
                        </div>
                        <div className="login__btn" onClick={()=>apiLogin()}>
                            เข้าสู่ระบบ
                        </div>
                        <div className="login__btnregister" onClick={()=>registerPage()}>
                            สมัครสมาชิก
                        </div>
                    </div>
                    <img src="https://kamihikokicafe.sgp1.digitaloceanspaces.com/DRAFT.png" alt='' className="login__banner"/>
                </div>
            </form>
            <Footer
                title="KAMI HIKōKI CAFE"
            />
            <LoadingModal/>
        </Page>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

const Page = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    // padding: 80px 0 0px;
    background: #946e3f;
    @media screen and (max-width: 450px){
        // padding: 58px 0 0px;
    }
    .login{
        &__inner{
            width: 100%;
            display: flex;
            //align-items: center;
            background: #fff;
            @media screen and (orientation: portrait) , (max-width: 850px){
                flex-direction: column-reverse;
            }
        }
        &__detail{
            width: 50%;
            padding: 2% 6%;
            display: flex;
            flex-direction: column;
            justify-content: center;
             @media screen and (orientation: portrait) , (max-width: 850px){
                width: 100%;
                padding: 0% 8% 8% 8%;
                text-align: center;
            }
        }
        &__banner{
            width: 50%;
            display: block;
            @media screen and (orientation: portrait) , (max-width: 850px){
                width: 100%;
            }
        }
        &__head{
            font-family: 'Kanit-Regular', 'tahoma';
            font-size: 1.6em;
            color: #946e3f;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__sub-head{
            font-size: 1.5em;
            color: #4E5456;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__text{
            font-family: 'Kanit-Light', 'tahoma';
            font-size: .8em;
            color: #4E5456;
        }
        &__row{
            width: 100%;
            margin: 3% auto;
            text-align: left;
            line-height: 0;
            @media screen and (orientation: portrait) , (max-width: 850px){
                max-width: 500px;
                text-align: center;
            }
        }
        &__row-text{
            font-family: 'Kanit-Regular', 'tahoma';
            display: inline-block;
            vertical-align: middle;
            font-size: .8em;
            color: #4E5456;
            width: 6em;
        }
        &__row-input{
            width: 70%;
            display: inline-block;
            vertical-align: middle;
            width: 15em;
            font-size: .8em;
            color: #4E5456;
            padding: .5em .5em;
            border: 1px solid #707070;
            border-radius: 5px;
        }
        &__btn{
            background: #946e3f;
            color: #fff;
            font-size: .8em;
            text-align: center;
            width: 8em;
            border-radius: 1.2em;
            padding: .5em 0;
            cursor: pointer;
            transition: .3s opacity;
            &:hover{
                opacity: .9;
            }
             @media screen and (orientation: portrait) , (max-width: 850px){
                margin: 0 auto;
            }
            
        }
        &__btnregister{
            background: #946e3f;
            color: #fff;
            font-size: .8em;
            text-align: center;
            width: 8em;
            border-radius: 1.2em;
            padding: .5em 0;
            cursor: pointer;
            transition: .3s opacity;
            margin-top : 1%;
            &:hover{
                opacity: .9;
            }
             @media screen and (orientation: portrait) , (max-width: 850px){
                margin: 1px auto;
            }
            
        }
    }
`;